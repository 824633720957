/* You can add global styles to this file, and also import other style files */

$Navy: #121212;
$LightNavy: #1e1e1e;
$LightestNavy: #2a2a2a;
$Slate: #8c8c8c;
$LightSlate: #a5a5a5;
$LightestSlate: #c0c0c0;
$White: #eaeaea;
$Blue: #3399cc;
$BlueOpacity: rgba(51, 153, 204, 0.07);

// Light Mode
$Light-Navy: #ffffff; // Sehr helles Hintergrundweiß
$Light-LightNavy: #f0f0f5; // Leicht abgedunkeltes Weiß für Sektionen
$Light-LightestNavy: #d9d9e2; // Weicher hellgrauer Akzent
$Light-Slate: #555555; // Dunkleres Grau für Text
$Light-LightSlate: #737373; // Etwas hellerer Text oder Akzentfarbe
$Light-LightestSlate: #a3a3a3; // Noch hellere Akzente
$Light-White: #eaeaea; // Ein weicherer Weißton für Kontraste
$Light-Blue: #1a73e8; // Blau (ersetzt Grün) für Akzente und Links
$Light-BlueOpacity: rgba(
  26,
  115,
  232,
  0.07
); // Abgemildertes Blau für Hover- oder Background-Effekte

$MainFont:
  Calibre,
  San Francisco,
  SF Pro Text,
  -apple-system,
  system-ui,
  BlinkMacSystemFont,
  Roboto,
  Helvetica Neue,
  Segoe UI,
  Arial,
  sans-serif;
$CodeFont:
  JetBrains Mono,
  SF Mono,
  Fira Code,
  Fira Mono,
  Roboto Mono,
  Lucida Console,
  Monaco,
  Monospace;
